.clinics-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.page-title {
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 2rem;
  animation: fadeInDown 0.8s ease-out;
}

.clinic-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.clinic-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: all 0.3s ease;
  text-decoration: none;
  color: #333;
  animation: fadeIn 0.8s ease-out forwards;
  opacity: 0;
}

.clinic-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.clinic-name {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #2c3e50;
}

.clinic-location {
  font-size: 1rem;
  color: #7f8c8d;
  margin-bottom: 1rem;
}

.clinic-details {
  font-size: 0.9rem;
  color: #34495e;
}

.clinic-details p {
  margin: 0.5rem 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.clinic-card:nth-child(1) { animation-delay: 0.1s; }
.clinic-card:nth-child(2) { animation-delay: 0.2s; }
.clinic-card:nth-child(3) { animation-delay: 0.3s; }
.clinic-card:nth-child(4) { animation-delay: 0.4s; }
.clinic-card:nth-child(5) { animation-delay: 0.5s; }
/* Add more if needed */