.blog-post {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: Arial, sans-serif;
  color: #333;
  animation: fadeIn 0.8s ease-out;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.blog-title {
  font-size: 2.5rem;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 2rem;
  animation: fadeInDown 0.8s ease-out;
}

.blog-content {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 2rem;
  animation: fadeIn 1s ease-out;
}

.blog-content h2 {
  font-size: 1.8rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.blog-content h3 {
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 0.8rem;
}

.blog-content p {
  line-height: 1.6;
  margin-bottom: 1rem;
}

.blog-content ul, .blog-content ol {
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.blog-content li {
  margin-bottom: 0.5rem;
}

.hidden-links {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .blog-post {
    padding: 1rem;
  }

  .blog-title {
    font-size: 2rem;
  }

  .blog-content h2 {
    font-size: 1.5rem;
  }

  .blog-content h3 {
    font-size: 1.3rem;
  }
}