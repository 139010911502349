.blog-list {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: Arial, sans-serif;
  color: #333;
  animation: fadeIn 0.8s ease-out;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.blog-list-title {
  font-size: 2.5rem;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 1rem;
  animation: fadeInDown 0.8s ease-out;
}

.blog-list-intro {
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 2rem;
}

.blog-post-list {
  list-style-type: none;
  padding: 0;
}

.blog-post-item {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-post-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.blog-post-link {
  display: block;
  padding: 1rem;
  color: #2c3e50;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.blog-post-link:hover {
  color: #3498db;
}

.hidden-links {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .blog-list {
    padding: 1rem;
  }

  .blog-list-title {
    font-size: 2rem;
  }

  .blog-list-intro {
    font-size: 1rem;
  }
}