.header {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 15px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-image {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.logo-text {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.nav {
  display: flex;
  gap: 20px;
}

.nav-link {
  color: #333;
  text-decoration: none;
  font-weight: 500;
}

.hero {
  position: relative;
  padding: 160px 0 120px;
  overflow: hidden;
  margin-top: 70px;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

.hero-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
  position: relative;
  z-index: 2;
}

.hero-title {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.8);
}

.hero-subtitle {
  font-size: 24px;
  color: #444;
  margin-bottom: 40px;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.8);
}

.button {
  padding: 15px 30px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.button:hover {
  background-color: #0056b3;
}

.button-inline {
  display: inline-flex;
  align-items: center;
}

.icon-right {
  margin-left: 10px;
}

.section {
  padding: 80px 0;
}

.section-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.section-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
  color: #333;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: 30px;
  text-align: center;
}

.card-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.card-text {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.footer {
  background-color: #333;
  color: #fff;
  padding: 40px 0;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
}

.footer-column {
  flex: 1;
}

.footer-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer-link {
  color: #fff;
  text-decoration: none;
  display: block;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }

  .footer-content {
    flex-direction: column;
  }

  .footer-column {
    margin-bottom: 30px;
  }

  .hero {
    padding: 120px 0 80px;
  }

  .hero-title {
    font-size: 36px;
  }

  .hero-subtitle {
    font-size: 20px;
  }

  .header-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav {
    margin-top: 15px;
  }
}