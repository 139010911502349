.clinic-details {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.8s ease-out;
}

.clinic-details h1 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 1rem;
  animation: fadeInDown 0.8s ease-out;
}

.clinic-details p {
  font-size: 1.1rem;
  color: #34495e;
  margin-bottom: 1rem;
  animation: fadeIn 0.8s ease-out;
}

.clinic-details h2 {
  font-size: 1.8rem;
  color: #2c3e50;
  margin-top: 2rem;
  margin-bottom: 1rem;
  animation: fadeInLeft 0.8s ease-out;
}

.clinic-details a {
  color: #3498db;
  text-decoration: none;
  transition: color 0.3s ease;
}

.clinic-details a:hover {
  color: #2980b9;
}

.clinic-info {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  animation: fadeIn 1s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}