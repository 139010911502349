.reviews-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: Arial, sans-serif;
  color: #333;
  animation: fadeIn 0.8s ease-out;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.page-title {
  font-size: 2.5rem;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 2rem;
  animation: fadeInDown 0.8s ease-out;
}

.content-section {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 2rem;
  animation: fadeIn 1s ease-out;
}

.content-section h3 {
  font-size: 1.8rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.content-section p {
  line-height: 1.6;
  margin-bottom: 1rem;
}

.content-section ul {
  padding-left: 1.5rem;
}

.content-section li {
  margin-bottom: 0.5rem;
}

.content-section a {
  color: #3498db;
  text-decoration: none;
  transition: color 0.3s ease;
}

.content-section a:hover {
  color: #2980b9;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.content-section:nth-child(odd) {
  animation-delay: 0.2s;
}

.content-section:nth-child(even) {
  animation-delay: 0.4s;
}

@media (max-width: 768px) {
  .reviews-page {
    padding: 1rem;
  }

  .page-title {
    font-size: 2rem;
  }

  .content-section h3 {
    font-size: 1.5rem;
  }
}